import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import './App.css';
import Footer from "./component/Layout/Footer/footer.js";
import Home from "./component/Home/home";
import Header from "./component/Layout/Header/header.js";
import Faq from "./component/Faq/faq.js"
import Aristocrat from "./component/Aristocrats/aristocrat.js";
import ScrollProgress from "./component/Layout/ScrollProgress/scrollProgress.js";
import AllStocks from "./component/AllStocks/allStocks.js";
import StockInfo from "./component/StockInfo/stockInfo.js";
import PostBlog from "./component/Admin/Blogs/postBlog.js";
import MonthlyStocks from "./component/MonthlyStocks/monthlyStocks.js";
import SearchPage from "./component/Search/search.js";
import Disclaimer from "./component/Disclaimer/disclaimer.js";
import PrivacyPolicy from "./component/PrivacyPolicy/privacyPolicy.js";
import Contact from "./component/Contact/contact.js";
import Blog from "./component/Blogs/blog.js";
import Admin from "./component/Admin/admin.js";
import UpdateBlogs from "./component/Admin/Blogs/updateBlogs.js";
import BlogPage from "./component/BlogPage/blogPage.js";
import StockVsStock from "./component/StockVsStock/stockVsStock.js";
import CompanyTag from "./component/CompanyTag/companyTag.js";
import BlogTag from "./component/BlogTag/blogTag.js";
import AllComparisons from "./component/AllComparisons/allComparisons.js";
import ChangePassword from "./component/Admin/changePassword.js";
import DividendCalendar from "./component/Calendar/calendar.js";
import CookieConsent, { Cookies } from "react-cookie-consent";

function App() {
  return (
    <Router>
      <ScrollProgress />
      <Header />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        enableDeclineButton
        declineButtonText="Decline"
        contentStyle={{marginRight:5}}
        declineButtonStyle = {{margin:10, color:"#1877f2", border:"1px #1877f2 solid", padding:10, borderRadius:3, background:"#fff"}}
        cookieName="permission"
        style={{alignItems:"center",background: "#ffffff", boxShadow: "0 -1px 10px -5px #00b962", padding:"10px 10%", width:"80%"}}
        buttonStyle={{margin:10, color:"#fff", background:"#1877F2", padding:10, borderRadius:3 }}
        expires={15000}
      >
        <span style={{color:"black"}}>We use cookies to improve your experience. By clicking "Accept", you agree to our use of cookies. Click "Decline" to opt-out.
        
        For details, see our <a href="/cookie-policy-privacy-statement" className='stockName'><b>Cookie Policy</b></a>.
        </span>
      </CookieConsent>
      <Routes>
        <Route exact path="/" element = {<Home />} />
        {/* <Route exact path="/faqs" element = {<Faq />} /> */}
        {/* <Route exact path="/aristocrats" element = {<Aristocrat />} /> */}
        <Route exact path="/all-stocks" element = {<AllStocks />} />
        <Route exact path="/c/:stockName" element = {<StockInfo />} />
        <Route exact path="/pehub78/blogs" element = {<PostBlog />} />
        {/* <Route exact path="/monthly" element = {<MonthlyStocks />} /> */}
        <Route exact path="/search" element = {<SearchPage />} />
        <Route exact path="/disclaimer" element = {<Disclaimer />} />
        <Route exact path="/cookie-policy-privacy-statement" element = {<PrivacyPolicy />} />
        <Route exact path="/contact" element = {<Contact />} /> 
        <Route exact path="/blog" element = {<BlogPage />} /> 
        <Route exact path="/:blogName" element = {<Blog />} /> 
        <Route exact path="/pehub78" element = {<Admin />} /> 
        <Route exact path="/pehub78/blogs/update/:blogName" element = {<UpdateBlogs />} /> 
        <Route exact path="/compare/:stockVsStock" element = {<StockVsStock />} /> 
        <Route exact path="/company-tag/:tag" element = {<CompanyTag />} /> 
        <Route exact path="/tag/:tag" element = {<BlogTag />} /> 
        <Route exact path="/all-stock-comparisons" element = {<AllComparisons />} /> 
        <Route exact path="/pehub78/change-password" element = {<ChangePassword />} /> 
        <Route exact path="/pehub78/change-password" element = {<ChangePassword />} /> 
        <Route exact path="/dividend-calendar" element = {<DividendCalendar />} /> 
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
