import React, {useState} from 'react';
import './contact.css';
import axiosInstance from "../../utils/axiosInstance";
import MoonLoader from "react-spinners/MoonLoader";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    channel: '',
    message: '',
  });

  const submitContactUs = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post("/general/contact-us", {
        first_name : formData.fname,
        last_name : formData.lname,
        email: formData.email,
        channel : formData.channel,
        comment : formData.message
      })
      setError("");
      setIsSubmitted(true);
    }catch(err) {
      console.log(err);
      setError(err.response.data.error);
      setIsLoading(false);
    }
    
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  if(isSubmitted) {
    return (
      <div style={{display:"flex", justifyContent:"center", flexDirection:'column', alignItems:"center", marginBottom:'30px'}}>
        <h2 class="underline" style={{fontSize:'40px'}}> Thank You! </h2>
        <p style={{fontSize:'20px', marginTop:0}}>Your response has been recorded.</p>
        <div style={{margin:"20px 0"}}>
          <a href="/" className='heroBtn'>Go back to Home</a>
        </div>
      </div>
    )
  }
  return (
    <div className="contact-form">
      <h2>Contact Us</h2>
      <span style={{color:"red", fontSize:20}}>{error}</span>
      {!isLoading? 
      <form onSubmit={submitContactUs}>
        <div className="form-group">
          <label htmlFor="name">Name <span className="required">*</span></label>
          <div className="name-inputs">
            <input onChange={handleChange} type="text" id="first-name" name="fname" placeholder="First" required />
            <input onChange={handleChange} type="text" id="last-name" name="lname" placeholder="Last" required />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="email">Email <span className="required">*</span></label>
          <input onChange={handleChange} type="email" id="email" name="email" required />
        </div>
        <div className="form-group">
          <label htmlFor="channel">How did you hear about us? <span className="required">*</span></label>
          <select onChange={handleChange} id="channel" name="channel" required>
            <option value="" disabled selected>Channel</option>
            <option value="internet">Google</option>
            <option value="friend">Social Media</option>
            <option value="advertisement">A Friend</option>
            <option value="other">Other Website</option>
            <option value="other">TV</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="message">Comment or Message <span className="required">*</span></label>
          <textarea onChange={handleChange} id="message" name="message" required></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
      :
      <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <MoonLoader
          color={"#22b273"}
          loading={isLoading}
          size={75}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>}
    </div>
  );
}

export default Contact;
